import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Button from 'components/dist/atoms/Button';
import { useRouter } from 'next/router';
import React, { FC, SyntheticEvent, useState } from 'react';
import ForgotPasswordPage from 'src/components/auth/forgot-password-page';
import LoginForm from 'src/components/auth/login-form/login-form';
import { SignUpFormValues } from 'src/components/auth/sign-up-form/sign-up-form';
import SignupPage from 'src/components/auth/sign-up-page';
import Footer from 'src/components/common/v2/footer';
import { Route } from 'src/constants/routing';
import { QUERY_PARAM_RETURN_URL } from "src/constants/url";
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import * as yup from 'yup';

import { ITEMS } from './landing-page.constants';
import { LandingPageStyles } from './landing-page.styles';

interface LandingPageProps {
  hideTopAlert?: boolean;
  hideRequestDemo?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

const LandingPage: FC<LandingPageProps> = ({
  hideTopAlert = false,
  hideRequestDemo = false,
  title = ' Streamlined Loan Solutions: Simplify and Accelerate the Loan Process',
  subTitle = `Our web-based platform revolutionizes the loan origination
            experience by seamlessly integrating automation and centralised
            collaboration.`,
}) => {
  const [signUpFormValues, setSignUpFormValues] =
    useState<SignUpFormValues | null>(null);
  const [tabSelected, setTabSelected] = useState<'login' | 'signup' | 'reset'>(
    'login'
  );
  const { neutral } = themeV2;
  const router = useRouter();
  const handleTabChange = (
    _event: SyntheticEvent,
    value: 'login' | 'signup' | 'reset'
  ) => setTabSelected(value);

  const onLoginSuccess = () => {
    // if we have a valid return url, redirect to it
    if (router.query[QUERY_PARAM_RETURN_URL] && yup.string().url().isValidSync(router.query[QUERY_PARAM_RETURN_URL] as string)) {
      router.push(router.query[QUERY_PARAM_RETURN_URL] as string);
      // otherwise, redirect to home
    } else {
      router.push({ pathname: Route.HOME });
    }
  };

  return (
    <Box sx={{ backgroundColor: neutral.white.default }}>
      {!hideTopAlert && <LandingPageStyles.TopAlert icon={false} severity="error">
        <LandingPageStyles.TopAlertTitle>
          Oops! the website you entered doesn’t exist. Contact your Lender or
          Login below
        </LandingPageStyles.TopAlertTitle>
      </LandingPageStyles.TopAlert>}
      <LandingPageStyles.Header
        direction={{ lg: 'row', xs: 'column' }}
        spacing={8}
      >
        <LandingPageStyles.HeaderLeft>
          {title && <LandingPageStyles.Title>
            {title}
          </LandingPageStyles.Title>}
          {subTitle && <LandingPageStyles.Subtitle>
            {subTitle}
          </LandingPageStyles.Subtitle>}
          {!hideRequestDemo && <LandingPageStyles.HeaderLink href="mailto:sales@mysherpas.ai">
            Request a Demo
          </LandingPageStyles.HeaderLink>}
        </LandingPageStyles.HeaderLeft>
        <LandingPageStyles.HeaderSignin className='w-full max-w-lg flex flex-col items-center'>
          <Tabs
            value={tabSelected}
            className='w-full'
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { height: 0 } }}
          >
            <LandingPageStyles.Tab
              selected={tabSelected === 'login'}
              value="login"
              label="Login"
            />
            <LandingPageStyles.HiddenTab selected={false} value="reset" />
            <LandingPageStyles.Tab
              selected={tabSelected === 'signup'}
              value="signup"
              label="Sign up"
            />
          </Tabs>
          <div className='pt-6 w-full flex flex-col items-center'>
            {tabSelected === 'login' && (
              <LoginForm
                showLogo={false}
                showSignupLink={false}
                title="Login"
                onLoginSuccess={onLoginSuccess}
                setTabSelected={setTabSelected}
              />
            )}
            {tabSelected === 'signup' && (
              <SignupPage
                signUpFormValues={signUpFormValues}
                setSignUpFormValues={setSignUpFormValues}
                showBackButton={false}
                showLogo={false}
                showLoginLink={false}
                title="Sign Up"
              />
            )}
            {tabSelected === 'reset' && (
              <ForgotPasswordPage
                showLogo={false}
                showBackButton={false}
                showCancelLink={false}
                onLoginSuccess={onLoginSuccess}
              />
            )}
          </div>
        </LandingPageStyles.HeaderSignin>
      </LandingPageStyles.Header>
      <LandingPageStyles.FeaturesSection>
        <LandingPageStyles.FeaturesTitle>
          One workspace, Accelerate Approvals
        </LandingPageStyles.FeaturesTitle>
        <LandingPageStyles.FeaturesSubtitle>
          We understand that time is of the essence when it comes to obtaining
          loans, and we are here to provide a hassle-free and efficient
          solution.
        </LandingPageStyles.FeaturesSubtitle>
        <LandingPageStyles.List>
          {ITEMS.map((item) => (
            <LandingPageStyles.ListItem key={item.title}>
              {item.icon}
              <LandingPageStyles.ItemTitle>
                {item.title}
              </LandingPageStyles.ItemTitle>
              <LandingPageStyles.ItemSubtitle>
                {item.subtitle}
              </LandingPageStyles.ItemSubtitle>
            </LandingPageStyles.ListItem>
          ))}
        </LandingPageStyles.List>
      </LandingPageStyles.FeaturesSection>
      <LandingPageStyles.CTASection>
        <LandingPageStyles.CTAHeading>
          Experience the future of lending
        </LandingPageStyles.CTAHeading>
        <LandingPageStyles.CTASubtitle>
          Unlock a seamless, fast, and transparent loan experience today.
        </LandingPageStyles.CTASubtitle>
        <Button
          className='mt-9 font-bold min-w-[280px]'
          size="lg"
          onClick={() => {
            setTabSelected('signup');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          Sign up for free
        </Button>
      </LandingPageStyles.CTASection>
      <Footer />
    </Box>
  );
};

export default LandingPage;
